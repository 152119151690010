<template>
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.625 1.875H0.375C0.15625 1.875 0 1.71875 0 1.5V0.5C0 0.3125 0.15625 0.125 0.375 0.125H13.625C13.8125 0.125 14 0.3125 14 0.5V1.5C14 1.71875 13.8125 1.875 13.625 1.875ZM13.625 6.875H0.375C0.15625 6.875 0 6.71875 0 6.5V5.5C0 5.3125 0.15625 5.125 0.375 5.125H13.625C13.8125 5.125 14 5.3125 14 5.5V6.5C14 6.71875 13.8125 6.875 13.625 6.875ZM13.625 11.875H0.375C0.15625 11.875 0 11.7188 0 11.5V10.5C0 10.3125 0.15625 10.125 0.375 10.125H13.625C13.8125 10.125 14 10.3125 14 10.5V11.5C14 11.7188 13.8125 11.875 13.625 11.875Z"
      fill="currentColor"
    />
  </svg>
</template>
